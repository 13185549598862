import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { get } from 'lodash-es';
import classNames from 'classnames';

import { STATUS_SUCCESS } from 'store/status';
import { DashboardTemplate } from 'modules/v2/templates';
import { withProfile } from 'modules/v2/containers';
import { withGlobalProfile } from 'modules/dashboard/containers';

import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { LogoUploader } from 'modules/v2/common/AtomicDesign/molecules';
import { PersonalInformationForm } from 'modules/v2/common/AtomicDesign/organisms';
import AddressGoogleLookup from 'modules/v2/common/components/AddressGoogleLookup';
import { ChecklistIcon, CheckIconGuides, DangerTriangleIcon } from 'modules/v2/common/components/SvgIcon';
import { AnalyticsContainer, BooksContainer, MagazineContainer } from '../HomePage/sections';

const HomePageGuides = ({ history, profile, globalProfile }) => {
  const { isRm, isBooks } = useSelector((data) => data.bundleAccess);
  const [isDigitalOnly, setIsDigitalOnly] = useState(false);
  const [firstNameUser, setFirstNameUser] = useState('');
  const [globalProfileData, setGlobalProfileData] = useState(null);
  const [isSetupCompleted, setIsSetupCompleted] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [setupForm, setSetupForm] = useState('personal-information');
  const [fieldValue, setFieldValue] = useState();
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [geocodeLocation, setGeocoderLocation] = useState(null);
  const [isAddressSameAsPublic, setIsAddressSameAsPublic] = useState(false);

  const subHeader = () => {
    if (isSetupCompleted) return null;

    return 'Finish this setup checklist to get your resources ready to go.';
  };

  const boughtGuideData = {
    image: 'https://afy-ui-stg.s3.us-east-1.amazonaws.com/images/seller/seller-guide-blue.png',
    title: 'A Guide to Selling Your Home + Infographic',
    hasInfographic: true,
    qtd: 20,
  };


  useEffect(() => {
    if (profile) {
      const { firstName } = get(profile, 'data', '');
      setFirstNameUser(firstName);
    }
  }, [profile]);

  useEffect(() => {
    if (globalProfile && globalProfile.status === STATUS_SUCCESS) {
      // GLOBAL PROFILE DATA TO FILL SETUP CHECKLIST FORM
      setGlobalProfileData(globalProfile);
    }
  }, [globalProfile, globalProfileData]);

  let content = null;

  if (setupForm === 'personal-information') {
    content =
      <div>
        <p className='mb-6 text-base font-semibold'>Your Personal Information</p>
        <PersonalInformationForm />
        <Button
          color='primary'
          className='mt-6'
          onClick={() => console.log('save changes')}
        >
          Save changes
        </Button>
      </div>
  }

  if (setupForm === 'address') {
    content =
      <div className='flex flex-col gap-6'>
        <p className='text-base font-semibold'>{`Your ${!isDigitalOnly ? 'Shipping' : ''} Address`}</p>
        <div className='flex items-center gap-3.5'>
          <button
            className={classNames('w-4 h-4 flex items-center justify-center rounded border border-neutral-200', isAddressSameAsPublic ? 'bg-primary-500' : 'bg-white')}
            onClick={() => setIsAddressSameAsPublic(!isAddressSameAsPublic)}
          >
            <CheckIconGuides width='10' height='10' />
          </button>
          <p>Same as your public address</p>
        </div>
        <AddressGoogleLookup
          setSelectedPlace={setSelectedPlace}
          setGeocoderLocation={setGeocoderLocation}
          setFieldValue={setFieldValue}
          setIsManualEntry={setIsManualEntry}
        />
        <Button
          color='primary'
          onClick={() => console.log('save changes')}
        >
          Save changes
        </Button>

      </div>
  }  

  return (
    <DashboardTemplate title={`Welcome back ${firstNameUser}`} subTitle={subHeader()}>
      <div className="rounded-md gap-2 mb-6">
        <div className="flex gap-x-6 flex-wrap max-xxl:gap-6 justify-between flex-col lg:flex-row">
          {isBooks ? (
            <Box className="p-6">
              <BooksContainer />
            </Box>
          ) : null}
          {isRm ? (
            <Box>
              <MagazineContainer />
            </Box>
          ) : null}
        </div>
        <AnalyticsContainer />
      </div>
      <Box>
        <div className='py-[22px] px-6 border-b border-neutral-200 font-semibold text-base'>
          <p>Your Guides</p>
        </div>
        <div className='p-6 flex gap-[18px] font-semibold'>
          <img src={boughtGuideData.image} alt={boughtGuideData.title} className='w-[100px] h-[131px]' />
          <div className='flex flex-wrap gap-2'>
            <div className='text-base'>
              {boughtGuideData.title}
            </div>
            <div className='py-0.5 px-1 rounded-md bg-neutral-100 text-sm h-fit'>
              {boughtGuideData.qtd} Printed Copies
            </div>
          </div>

        </div>
      </Box>

      <Box className='mt-6'>
        <div className='flex items-center gap-3 py-2.5 px-3 bg-orange-50'>
          <DangerTriangleIcon />
          <div className='text-base text-orange-600 font-semibold'>Please complete the setup checklist below for us to ship your guides to you.</div>
        </div>
        <div className='flex w-full max-lg:flex-col'>
          <div className='p-6 w-full max-w-[300px] border-r border-neutral-200 max-lg:border-none'>
            <button onClick={() => setSetupForm('personal-information')} className='w-full'>
              <div className='text-lg font-semibold flex items-center gap-2.5 mb-3.5'>
                <ChecklistIcon />
                <span>Setup checklist</span>
              </div>

              <div className='text-sm font-semibold flex items-center gap-3 py-2.5 px-3 rounded-md bg-neutral-100'>
                <div className={classNames('w-4 h-4 rounded-full flex items-center justify-center border border-neutral-200', setupForm === 'personal-information' ? 'bg-success-500' : 'bg-white')}>
                  <CheckIconGuides width='10' height='10' />
                </div>
                <span>Your Personal Information</span>
              </div>
            </button>

            <button onClick={() => setSetupForm('address')} className='w-full'>
              <div className='text-sm font-semibold flex items-center gap-3 py-2.5 px-3 rounded-md bg-neutral-100 mt-2'>
                <div className={classNames('w-4 h-4 rounded-full flex items-center justify-center border border-neutral-200', setupForm === 'address' ? 'bg-success-500' : 'bg-white')}>
                  <CheckIconGuides width='10' height='10' />
                </div>
                <span>{isDigitalOnly ? 'Your Address' : 'Your Shipping Address'}</span>
              </div>
            </button>
          </div>
          <div className='p-6 w-full max-w-screen-lg'>{content}</div>
        </div>
      </Box>
    </DashboardTemplate>
  );
};

export default compose(withProfile, withGlobalProfile)(HomePageGuides);
