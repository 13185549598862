import { useState } from 'react';
import { compose } from 'redux';

import { isReady } from 'store/status';
import { Loader, UserAvatar } from 'modules/v2/common/components';

import { ProfileAvatar } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { LogoUploader } from 'modules/v2/common/AtomicDesign/molecules';
import { EditProfileForm, FileUploadModal } from 'modules/v2/components';
import AddressGoogleLookup from 'modules/v2/common/components/AddressGoogleLookup';
import { withProfile } from 'modules/v2/containers';
import { withAuth } from 'modules/auth/containers';

const PersonalInformationForm = ({ avatar, uploadAvatar, profile, updateProfile, logo, uploadLogo, hideAddress = false }) => {
    const [loadingText, setLoadingText] = useState('Loading Profile');
    const [isOpen, setIsOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [fieldValue, setFieldValue] = useState();
    const [isManualEntry, setIsManualEntry] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [geocodeLocation, setGeocoderLocation] = useState(null);

    const {
        data: { email, firstName, lastName, phone },
    } = profile;

    const fields = {
        email,
        firstName,
        lastName,
        phone,
    };

    const hasAvatar = !!avatar?.data?.url;

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleConfirm = (newFile) => {
        setIsOpen(false);

        uploadAvatar(newFile);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const handleProfileUpdate = (formFields) => {
        setLoadingText('Saving profile');

        updateProfile(formFields);
    };

    let content = <Loader title={loadingText} height="100%" />;

    if (isReady(profile.status)) {
        content = (
            <div>
                {hasAvatar ? (
                    <div className="h-[120px] mt-3.5 mb-6 flex items-center gap-6">
                        <div className="w-[120px]">
                            <UserAvatar avatar={avatar} bordered
                                defaultAvatar={<ProfileAvatar />} size="120px" />
                        </div>
                        <Button type="outlined" onClick={handleOpen}>
                            Change
                        </Button>
                    </div>
                ) : (
                    <Button type="outlined" onClick={handleOpen} className="max-w-[492px] mt-3.5 mb-6" full>
                        Upload photo
                    </Button>
                )}
                <EditProfileForm fields={fields} onSave={handleProfileUpdate} showSubmitButton={false} />
                {isOpen && (
                    <FileUploadModal
                        isOpen={isOpen}
                        title="Upload avatar"
                        type="profile"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                )}
                {!hideAddress && (
                    <div className='border-y border-neutral-200 py-6 mb-6'>
                        <div className='mb-6'>
                            <p className='text-base font-semibold'>Your Public Address</p>
                            <p className='text-sm font-medium text-neutral-500'>The address you enter here will appear in your printed materials. Please note, this is not your shipping address.</p>
                        </div>
                        <div>
                            <AddressGoogleLookup
                                setSelectedPlace={setSelectedPlace}
                                setGeocoderLocation={setGeocoderLocation}
                                setFieldValue={setFieldValue}
                                setIsManualEntry={setIsManualEntry}
                            />
                        </div>
                    </div>
                )}
                <div className='p-3.5 bg-neutral-50 border border-neutral-200 rounded-md'>
                    <LogoUploader imageUrl={imageUrl} setImageUrl={setImageUrl} />
                </div>
            </div>
        );
    }

    return (
        <div className="w-full">            
            {content}
        </div>
    );
};

export default compose(withProfile, withAuth)(PersonalInformationForm);
